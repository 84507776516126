import React from 'react';
import '../App.css';
import './HeroSection1.css';

function HeroSection1() {
  return (
    <div id="HOME" className='hero-container'>
      <video src='/images/video-6.mp4' autoPlay loop muted />
      <h1>PROJEKT Marek Wilk</h1>
      <p>Usługi informatyczne</p>
    </div>
  );
}

export default HeroSection1;