import React from 'react'
import HeroSection from '../../HeroSection'
import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo} from './Data.js'

function Home() {
    return (
        <>
            <div id="USŁUGI"><HeroSection {...homeObjOne} /></div>
            <div id="OBSŁUGA"><HeroSection {...homeObjTwo} /></div>
            <div id= "SIECI" ><HeroSection {...homeObjThree} /></div>
            <div id="STRONY"><HeroSection {...homeObjFour} /></div>
        </>
    )
}

export default Home

