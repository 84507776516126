import React from 'react';
import HeroSection1 from './HeroSection1';

function Home1() {
  return (
    <>
      <HeroSection1 />
    </>
  );
}

export default Home1;