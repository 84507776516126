import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div id ="footer" className='footer-container'>
      <section className='footer-subscription'>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div   className='footer-link-items'>
            <h2>PROJEKT Marek Wilk</h2>
            <p>ul. Jaśminowa 3</p>
            <p>36-100 Kolbuszowa</p>
            <p>marek.projekt@gmail.com</p>
            <p>NIP: 8141552504</p>
          
          </div>
          
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='social-icons'>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;