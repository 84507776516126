import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { BsDisplay } from 'react-icons/bs';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
  }, []);


  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar'>
          <div className='navbar-container container'>
          <a href="#HOME" className="navbar-logo" onClick={closeMobileMenu}>
                <BsDisplay className = "navbar-icon"/>
                PROJEKT    
                    </a>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <a href="#USŁUGI"  className='nav-links' onClick={closeMobileMenu}>
                USŁUGI
                </a>
              </li>
              <li className="nav-item">
                    <a href="#OBSŁUGA" className="nav-links" onClick={closeMobileMenu}>
                    OBSŁUGA INFORMATYCZNA 
                    </a>
                </li> 
                <li className="nav-item">
                    <a href="#SIECI" className="nav-links" onClick={closeMobileMenu}>
                    SIECI KOMPUTEROWE
                    </a>
                </li> 
                <li className="nav-item">
                    <a href="#STRONY" className="nav-links" onClick={closeMobileMenu}>
                    STRONY WWW 
                    </a>
                </li> 
               
                <li  className="nav-item">
                    <a  href="#footer" className="nav-links" onClick={closeMobileMenu}>
                        KONTAKT
                    </a>
                </li> 
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;