export const homeObjOne  = {
    lightBg: false,
    lightText: false,
    lightTextDesc: true,
    // topLine: 'Usługi edukacyjne',
    headline: 'USŁUGI',
    description: "-Kompleksowa opieka nad sprzętem informatycznym w firmie i w domu;\n-Serwis komputerowy;\n-Sprzedaż sprzętu i oprogramowania;\n-Instalacja i naprawa systemów operacyjnych oraz programów;\n-Serwis i tworzenie sieci komputerowe;\n-Usuwanie wirusów komputerowych;\n-Bezpieczeństwo infrastruktury informatycznej;\nPrzyspieszanie komputerów;Instruktor CISCO,\n-Egzaminator ECDL (PL-E3825);\n-Usługi szkoleniowe z IT;\n-Szeroki wachlarz szkoleń komputerowych",
    imgStart: 'start',
    img: 'images/svg-2.png',
  };


export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'OBSŁUGA INFORMATYCZNA SZKÓŁ I FIRM',
  description: "-Wdrażanie i obsługa e-nauczania (google CLASSROOM);\n-Administracja siecią internetową;\n-Opieka nad komputerami i pracownią informatyczną;\n-Zarządzanie oprogramowaniem i licencjami;\n-Okresowa konserwacja sprzętu komputerowego;\n-Naprawy serwisowe laptopów i komputerów;\n-Doradztwo w zakresie systemów informatycznych;\n-Sprzedaż nowego i poleasingowego sprzętu komputerowego;\n-Opieka nad serwisem internetowym;",
  // imgStart: 'start',
  img: 'images/svg-7.png',
};


export const homeObjThree  = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: 'Usługi edukacyjne',
  headline: 'SIECI KOMPUTEROWE',
  description: '-Projektowanie i budowa sieci komputerowych;\n-Wykrywanie problemów sieciowych i ich usuwanie;\n-Konfiguracja sieci przewodowej i bezprzewodowej;\n-Konfiguracja routerów przewodowych i bezprzewodowych;\n-Zabezpieczenie sieci LAN i WiFi;\n-Dostawa sprzętu sieciowego;\n-Doradztwo\n‎‎‎⠀\n ⠀\n \n ⠀⠀',
  imgStart: 'start',
  img: 'images/svg-3.png',
};

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'PROJEKTOWANIE, WDRAŻANIE I POZYCJONOWANIE STRON WWW',
  headline: 'STRONY WWW',
  description:'-Wizytówka Online (najprostsza strona internetowa tzw. one page);\n-Statyczna Strona Internetowa (strona internetowa posiadająca kilka podstron. Zazwyczaj jest to home, o nas, oferta, kontakt. Strona dedykowana dla osób prywatnych oraz firm z niezmienną ofertą);\n-Strona Internetowa z CMS (Dynamiczna strona internetowa posiadająca kilka podstron. Dedykowana dla osób prywatnych i firm, które chcą zmieniać i aktualizować samodzielnie treści na swojej stronie);\n\n-Hosting w ramach kompleksowej oferty do każdej strony internetowej proponujemy profesjonalny hosting oraz domenę.',
  // imgStart: 'start',
  img: 'images/svg-6.png',
};

  



